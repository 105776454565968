.NumberInput {
  height: 3rem;
  border: 1px solid #e8e8e8;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input {
    height: 2rem;
    border: none;
    outline: none;
    padding-left: 1rem;
  }
  .numb-cont {
    height: 3rem;
    width: 10rem;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    color: #202020;
  }
  .arrow-cont-in {
    .cont {
      background: #5c5f62;
      width: 2.7rem;
      height: 1.5rem;
      margin-bottom: 0.1rem;
      border-top-right-radius: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .image {
        height: 0.6rem;
        width: 0.6rem;
        transform: rotate(180deg);
      }
    }
    .cont-2 {
      background: #5c5f62;
      width: 2.7rem;
      height: 1.5rem;
      margin-top: 0.1rem;
      border-bottom-right-radius: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .image {
        height: 0.6rem;
        width: 0.6rem;
      }
    }
  }
}
