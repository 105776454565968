@import "../../colors/colors.scss";
.Button {
  color: $white;
  background: $primary-gradient;
  width: max-content;
  padding: 0.75rem 4rem;
  font-family: "Inter";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
  text-align: center;
  text-transform: capitalize;
  border-radius: 0.25rem;
  cursor: pointer;
  outline: none;
  border: none;
  .loading-ic {
    border: 0.1rem solid $white;
    border-top: 0.15rem solid transparent;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.auth-gradient {
  background: linear-gradient(50.47deg, #f68bff 0%, #6f47eb 83.75%);
  width: 100%;
  height: 3rem;
  .loading-ic {
    margin: auto;
  }
}
