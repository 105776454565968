.Dropdown {
  position: relative;
  display: inline-block;
  width: 30%;
  border: 1px solid #e4e4e4;
  padding: 0.4rem 0.875rem;
  border-radius: 0.3rem;
  margin-right: 2.5rem;

  .cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    img {
      height: 0.75rem;
      transform: rotate(180deg);
    }
    .dropdown-button {
      font-family: "Inter";
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #19191c;
    }
  }

  .dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 2px 6px 0px #0000001f;
    z-index: 1;
    margin-left: -0.75rem;
    border-radius: 0.5rem;
    .org {
      font-family: "Inter";
      font-size: 10px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #808080;
      display: block;
      padding: 0.6rem 0.875rem;
      cursor: pointer;
    }
    .location {
      font-family: "Inter";
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #19191c;
      display: block;
      padding: 0.6rem 0.875rem;
      cursor: pointer;
    }

    .location:hover {
      background-color: #eee9fd;
    }
  }
}
