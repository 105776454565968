.status-modal-content {
  background: white;
  width: 15rem;
  border: 1px solid #ebebeb;
  height: 90vh;
  margin: auto;
  position: absolute;
  left: 27.7%;
  z-index: 1000;
  top: 0;
  .header {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    img {
      height: 1rem;
      width: 1rem;
      filter: brightness(0) saturate(100%) invert(4%) sepia(2%) saturate(4659%)
        hue-rotate(201deg) brightness(99%) contrast(87%);
      cursor: pointer;
    }
  }

  .content {
    overflow: auto;
    height: 82vh;
    .cat {
      display: flex;
      justify-content: space-between;
      background-color: #e1e1e1;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 600;
      padding: 0.625rem 1rem;
      margin-bottom: 0.2rem;
      img {
        height: 0.75rem;
        width: 0.75rem;
        filter: brightness(0) saturate(100%) invert(26%) sepia(13%)
          saturate(280%) hue-rotate(155deg) brightness(98%) contrast(88%);
        transform: rotate(180deg);
      }
    }
    .items {
      padding: 0.625rem 1rem;
      .item-cat {
        margin-bottom: 0.5rem;
        border-radius: 0.25rem;
        background-color: antiquewhite;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        padding: 0;
        padding: 0.5rem 0.625rem;
        display: flex;
        column-gap: 0.5rem;
        align-items: center;
        cursor: pointer;
        img {
          height: 0.75rem;
          width: 0.75rem;
        }
      }
    }
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
