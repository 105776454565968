.FloatingTab {
  position: fixed;
  width: 75%;
  height: 3rem;
  background-color: #ffffff;
  bottom: 1.2rem;
  box-shadow: 0px 16px 30px -6px #19191c29;
  right: 2.5rem;
  border-radius: 0.5rem;
  padding: 1.2rem 1.7rem;
  .inner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .deatils-cont {
      display: flex;
      align-items: center;
      .ic-conatiner-1 {
        height: 2.5rem;
        width: 2.5rem;
        background-color: #f8f9fd;
        border-radius: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .text-cont {
      margin-left: 1rem;
      border-right: 1px solid #e4e4e4;
      padding-right: 2rem;
      .header {
        font-family: "Inter";
        font-size: 0.95rem;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #19191c;
      }
      .sub {
        font-family: "Poppins";
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 16.6px;
        color: #828487;
      }
    }
    .reserve-cont {
      position: relative;
      background: #20c9ac1a;
      height: 2.8rem;
      width: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 2rem;
      border-radius: 0.5rem;
      img {
        height: 1.5rem;
      }
      .count-not {
        height: 1.188rem;
        width: 1.188rem;
        position: absolute;
        font-family: "Poppins";
        font-size: 10px;
        font-weight: 600;
        line-height: 15px;
        color: #fff;
        background: #20c9ac;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -0.4rem;
        top: -0.4rem;
      }
    }
    .cover-cont {
      position: relative;
      background: #00a5ff1a;
      height: 2.8rem;
      width: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1.2rem;
      border-radius: 0.5rem;
      img {
        height: 1.2rem;
      }
      .count-not {
        height: 1.188rem;
        width: 1.188rem;
        position: absolute;
        font-family: "Poppins";
        font-size: 10px;
        font-weight: 600;
        line-height: 15px;
        color: #fff;
        background: #00a5ff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -0.4rem;
        top: -0.4rem;
      }
    }
    .arrow-container {
      img {
        height: 1rem;
        width: 1rem;
        transform: rotate(90deg);
      }
    }
  }
}
.FloorFloatingTab {
  position: fixed;
  height: 3rem;
  background-color: #ffffff;
  bottom: 1.2rem;
  border: 0.97px solid #0000001a;
  box-shadow: 0px 5px 10px -4.72px #19191c29;
  border-radius: 0.5rem;
  margin-left: 3%;
  padding: 0.75rem 1rem;
  overflow: auto;
  .inner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .deatils-cont {
      display: flex;
      align-items: center;
      .ic-conatiner-1 {
        height: 1.5rem;
        width: 1.5rem;
        background-color: #f8f9fd;
        border-radius: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .text-cont {
      border-right: 1px solid #e4e4e4;
      padding-right: 2rem;
      margin-left: 0.75rem;
      .header {
        font-family: "Inter";
        font-size: 0.95rem;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #19191c;
      }
      .sub {
        font-family: "Poppins";
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 16.6px;
        color: #828487;
      }
    }
    .reserve-cont {
      position: relative;
      background: #20c9ac1a;
      height: 2.5rem;
      width: 2.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1.5rem;
      border-radius: 0.5rem;
      img {
        height: 1.5rem;
      }
      .count-not {
        height: 1.188rem;
        width: 1.188rem;
        position: absolute;
        font-family: "Poppins";
        font-size: 10px;
        font-weight: 600;
        line-height: 15px;
        color: #fff;
        background: #20c9ac;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -0.4rem;
        top: -0.4rem;
      }
    }
    .cover-cont {
      position: relative;
      background: #00a5ff1a;
      height: 2.5rem;
      width: 2.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
      border-radius: 0.5rem;
      img {
        height: 1.2rem;
      }
      .count-not {
        height: 1.188rem;
        width: 1.188rem;
        position: absolute;
        font-family: "Poppins";
        font-size: 10px;
        font-weight: 600;
        line-height: 15px;
        color: #fff;
        background: #00a5ff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -0.4rem;
        top: -0.4rem;
      }
    }
  }
}
