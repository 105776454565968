.time-modal-content {
  background: white;
  width: 10rem;
  border: 1px solid #ebebeb;
  height: max-content;
  margin: auto;
  position: absolute;
  left: 27.7%;
  z-index: 1000;
  top: 0;
  .header {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    img {
      height: 1rem;
      width: 1rem;
      filter: brightness(0) saturate(100%) invert(4%) sepia(2%) saturate(4659%)
        hue-rotate(201deg) brightness(99%) contrast(87%);
      cursor: pointer;
    }
  }
  .content {
    height: 85vh;
    overflow-y: auto;
    .shift-name {
      border-bottom: 1px solid #ebebeb;
      font-family: "Inter";
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3.25rem;
      cursor: pointer;
      background-color: #eee9fd;
      .gradient-text {
        background: linear-gradient(50.47deg, #f68bff 0%, #6f47eb 83.75%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
      }
    }
    .item-duration {
      border-bottom: 1px solid #ebebeb;
      font-family: "Inter";
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3.25rem;
      cursor: pointer;
    }
    .selected {
      background: #9494c51a;
      .gradient-text {
        background: linear-gradient(50.47deg, #f68bff 0%, #6f47eb 83.75%);

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
      }
    }
  }
}
