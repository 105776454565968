.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: zoomIn 0.3s ease-in-out;
  .header {
    font-family: "Inter";
    font-size: 1.2rem;
    font-weight: 500;
  }
  .err-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    .error {
      height: 3rem;
      width: 3rem;
    }
  }

  .content {
    font-family: "Inter";
    font-size: 0.875rem;
    font-weight: 300;
    color: #84818a;
    margin-top: 2rem;
    line-height: 1.5rem;
    text-align: center;
  }
  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    column-gap: 2rem;
    .close {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 500;
      color: #1a1a1a;
      cursor: pointer;
    }
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
