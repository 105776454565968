@import "../../colors/colors.scss";

.TimeSlot {
  margin-top: 0.5em;
  .timeslot-row {
    display: flex;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    overflow: hidden;
    border: 1px solid $border-grey;
    margin-bottom: 0.3rem;

    .single-slot {
      width: 25%;
      box-sizing: border-box;
      text-align: center;
      padding: 0.9rem 2rem;
      cursor: pointer;

      .time {
        font-family: "Inter";
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.2rem;
        text-align: center;
      }

      .time-dif {
        font-family: "Inter";
        font-size: 0.7rem;
        font-weight: 500;
        line-height: 1rem;
        text-align: center;
        color: $light-grey;
      }

      &:not(:nth-child(4n)) {
        border-right: 1px solid $border-grey; /* Right border for each column */
      }

      &:not(:nth-last-child(-n + 4)) {
        border-bottom: 1px solid $border-grey; /* Bottom border for each row */
      }
    }
    .selected {
      background-color: #9494c5;
      .time-dif {
        color: #fff;
      }
      .time {
        color: #fff;
      }
    }
    .single-slot:hover {
      background-color: #9494c52d;
    }
    .selected:hover {
      background-color: #9494c5;
    }
    .disable {
      background-color: $medium-grey;
      color: #aaaaaa;
    }
    .disable:hover {
      background-color: $medium-grey;
      cursor: not-allowed;
    }
  }
}
