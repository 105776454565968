.FloorView {
  width: 100%;
  position: relative;
  padding-bottom: 2%;
  .header-container {
    display: flex;
    padding-top: 1.375rem;
    width: 100%;
    border-bottom: 1px solid #ededed;
    background-color: #ffffff !important;
    position: fixed;
    top: 4rem;
    z-index: 10;
    height: 2rem;

    .content {
      width: max-content;
      text-align: center;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 600;
      color: #84818a;
      padding-bottom: 0.75rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      cursor: pointer;
    }
    .content-selected {
      color: #2e2c34;
      border-bottom: 2px #6f47eb solid;
    }
  }
  .alert-danger {
    width: 78%;
    margin: auto;
    border: 1px solid #ebadad;
    background-color: #faebeb;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .left-content {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
      width: 90%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem;
        width: 1rem;
        background-color: #cc3333;
        border-radius: 50%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: center;
        img {
          height: 0.5rem;
          width: 0.5rem;
        }
      }
    }
    .right-content {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      img {
        height: 0.75rem;
        width: 0.75rem;
        filter: brightness(0) saturate(100%) invert(52%) sepia(2%) saturate(3%)
          hue-rotate(326deg) brightness(97%) contrast(80%);
      }
    }
  }
  .alert-warning {
    width: 78%;
    margin: auto;
    border: 1px solid #f7e1a1;
    background-color: #fdf8e8;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .left-content {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
      width: 90%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem;
        width: 1rem;
        background-color: #deaf21;
        border-radius: 50%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: center;
        .danger {
          height: 0.5rem;
          width: 0.5rem;
        }
        .warning {
          height: 0.85rem;
          width: 0.85rem;
        }
      }
    }
    .right-content {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      img {
        height: 0.75rem;
        width: 0.75rem;
        filter: brightness(0) saturate(100%) invert(52%) sepia(2%) saturate(3%)
          hue-rotate(326deg) brightness(97%) contrast(80%);
      }
    }
  }
  .floor {
    height: 540px;
    min-width: 96%;
    margin: 2%;
    box-shadow: 0px 4px 4px 0px #0000001a;
    border-radius: 0.25rem;
    background-image: radial-gradient(#dddddd 0.063rem, transparent 0);
    background-size: 1.2rem 1.2rem;
    position: relative;
    margin-top: 5rem;
  }
  .selected-tables {
    background-color: #fff;
    height: max-content;
    width: 76%;
    box-shadow: 0px 16px 30px -6px #19191c29;
    margin: auto;
    margin-top: 1.563rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    padding: 1.5rem;
    .table-det {
      display: flex;
      align-items: center;
      img {
        height: 1.125rem;
        width: 1.125rem;
      }
      .text-cont {
        border-right: 1px solid #e4e4e4;
        padding-right: 2rem;
        margin-left: 1.5rem;
        .header {
          font-family: "Inter";
          font-size: 0.95rem;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
          color: #19191c;
        }
        .sub {
          font-family: "Poppins";
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 16.6px;
          color: #828487;
        }
      }
    }
    .grid-tables {
      display: flex;
      margin-left: 2rem;
      column-gap: 1rem;
      .table-item {
        font-family: "Poppins";
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.125rem;
        text-align: center;
        border: 1px solid #e4e4e4;
        padding: 0.5rem 0.4rem;
        border-radius: 0.25rem;
        position: relative;
        min-width: 3rem;
        .close {
          position: absolute;
          height: 1rem;
          width: 1rem;
          background-color: #ea4f3b;
          border-radius: 50%;
          top: -0.4rem;
          right: -0.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            height: 0.55rem;
            width: 0.5rem;
          }
        }
      }
      .empty {
        font-family: "Poppins";
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.125rem;
        text-align: center;
        color: #84818a;
      }
    }
  }
}
