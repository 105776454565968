.Drawer {
  width: 16.2rem;
  height: 100vh;
  background-color: #f9f9f9;
  border-right: 1px solid #ededed;
  position: fixed;

  .homeIC {
    height: 1.75rem;
    width: max-content;
    padding-top: 1.125rem;
    padding: 0.5rem 1.25rem;
    margin: 1rem;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 26.63px;
    letter-spacing: 0.01em;
    text-align: left;
    column-gap: 0.688rem;
    margin-bottom: 2.125rem;
    cursor: pointer;
  }
  .add-cont {
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
    padding: 0.5rem 1.25rem;
    margin: 1rem;
    border-radius: 0.375rem;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #5c5f62;
    column-gap: 0.75rem;
    cursor: pointer;
    .addBtn {
      background-color: #6f47eb;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }

  .list {
    .iconList {
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;
      padding: 0.5rem 1.25rem;
      margin: 1rem;
      border-radius: 0.375rem;
      font-family: "Inter";
      font-size: 13px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: #5c5f62;
      column-gap: 0.75rem;
      cursor: pointer;

      img {
        height: 1rem;
        width: 1rem;
      }
    }
    .iconListSelected {
      background: #eee9fd;
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;
      padding: 0.5rem 1.25rem;
      margin: 1rem;
      border-radius: 0.375rem;
      font-family: "Inter";
      font-size: 13px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.01em;
      column-gap: 0.75rem;
      cursor: pointer;
      div {
        background: -webkit-linear-gradient(
          50.47deg,
          #f68bff 0%,
          #6f47eb 83.75%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      img {
        height: 1rem;
        width: 1rem;
      }
    }
    .subtype {
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;
      padding: 0rem 3rem;
      margin: 1rem;
      border-radius: 0.375rem;
      font-family: "Inter";
      font-size: 12px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: #5c5f62;
      column-gap: 0.75rem;
      cursor: pointer;
    }
    .subtype-selected {
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;
      padding: 0rem 3rem;
      margin: 1rem;
      border-radius: 0.375rem;
      font-family: "Inter";
      font-size: 12px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.01em;
      background: -webkit-linear-gradient(50.47deg, #f68bff 0%, #6f47eb 83.75%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      column-gap: 0.75rem;
      cursor: pointer;
    }
  }
  .expand {
    position: absolute;
    bottom: 3rem;
    right: 1.5rem;
    height: 1.6rem;
    width: 1.6rem;
    background-color: #6f47eb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    img {
      height: 0.75rem;
      width: 0.75rem;
      transform: rotate(-90deg);
      margin-left: -0.3rem;
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
        hue-rotate(285deg) brightness(105%) contrast(102%);
    }
  }
}
