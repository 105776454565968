.SearchInput {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  .search {
    height: 0.9rem;
    width: 0.9rem;
    filter: brightness(0) saturate(100%) invert(56%) sepia(8%) saturate(270%)
      hue-rotate(219deg) brightness(90%) contrast(90%);
  }
  .search-input {
    border: none;
    outline: none;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    background-color: transparent;
  }
  .search-input::placeholder {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    color: #84818a;
  }
}
