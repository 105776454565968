.FloorLayout {
  position: relative;
  height: 100%;
  width: 100%;
  .table-bg {
    padding: 0.625rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
}
