.Table {
  cursor: pointer;

  .chair {
    height: 0.5rem;
    width: 1.875rem;
    border: 1px solid #0000002e;
    border-radius: 1.063;
    background-color: #fff;
    border-radius: 0.5rem;
  }
  .top {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
    height: 11px;
    .chair-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
    .chair-cont {
      transform: rotate(90deg);
      margin: -6px;
    }
    .table-cont {
      background-color: #fff;
      border: 1px solid #0000002e;
      height: 100%;
      width: 100%;
      border-radius: 0.588rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .inner-cont {
        background-color: #eee9fd;
        height: 60%;
        width: 60%;
        border-radius: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          height: 1.5rem;
          width: 1.5rem;
          background-color: #fff;
          border-radius: 50%;
        }
        .gradient-text {
          font-family: "Poppins";
          font-size: 0.75rem;
          font-weight: 600;
          background: -webkit-linear-gradient(
            50.47deg,
            #f68bff 0%,
            #6f47eb 83.75%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    .chair-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
