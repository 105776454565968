@import "../../colors/colors.scss";
.page {
  min-height: 100vh;
  height: auto;
  width: 100%;
  background-color: $primary-blue;

  .border {
    width: 100%;
    height: 0.3rem;
    background-color: $secondary-brown;
  }
  .loader {
    border: 0.15rem solid $secondary-brown;
    border-top: 0.15rem solid #ffffff52;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    animation: spin 1s linear infinite;
    margin: auto;
    margin-top: 20%;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .BookingPageOne {
    height: max-content;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2%;
    .left-container {
      height: auto;
      width: 45%;
      display: flex;
      .logo-witmeg {
        height: 5.1rem;
        width: 7.1rem;
        margin: auto;
        margin-top: 2rem;
        margin-left: -6%;
        img {
          height: 5.1rem;
          width: 7.1rem;
        }
      }
    }
    .right-container {
      margin-top: 5%;
      height: max-content;
      width: 45%;
      .form-container {
        height: 90%;
        width: 100%;
        background-color: $white;
        border-radius: 0.25rem;
        padding: 0.75rem;
        overflow-y: auto;
        label {
          margin-bottom: 0.5rem;
          color: #444444;
          font-family: "Inter";
          font-size: 0.8rem;
          font-weight: 500;
          line-height: 1.2rem;
          margin-left: 0.5rem;
        }
        .time-label {
          margin-top: 1.5rem;
        }
        .guest-and-date-selector {
          display: flex;
          gap: 1rem;
          margin-bottom: 1rem;
          width: 100%;
          margin-top: 2rem;
          .dropdown {
            display: flex;
            flex-direction: column;
            width: 50%;

            select {
              padding: 1rem 1rem;
              font-size: 1rem;
              border-radius: 0.25rem;
              border: 1px solid $border-grey;
              outline: none;
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 600;
              line-height: 1.2rem;
              color: #202020;
              -webkit-appearance: none; /* Remove default arrow in Chrome and Safari */
              -moz-appearance: none; /* Remove default arrow in Firefox */
              appearance: none; /* Remove default arrow in other browsers */
              background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><path d="M0 0l5 5 5-5H0z" fill="black"/></svg>');
              background-repeat: no-repeat;
              background-position: right 0.7rem top 50%, 0 0; /* Position the arrow icon */
              background-size: 10px auto, 100%;
            }
          }
        }
      }
      .button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 1.5rem;
      }
    }
  }
  .BookingPageThree {
    height: max-content;
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 10%;
    padding-bottom: 2%;
    .left-container {
      height: auto;
      width: 30%;
      display: flex;
      .logo-witmeg {
        height: 5.1rem;
        width: 7.1rem;
        margin: auto;
        margin-top: 2rem;
        margin-left: -16%;
        img {
          height: 5.1rem;
          width: 7.1rem;
        }
      }
    }
    .right-container {
      margin-top: 5%;
      height: max-content;
      width: 35%;
      .form-container {
        height: 95%;
        width: 100%;
        background-color: $white;
        border-radius: 0.25rem;
        padding: 0.5rem;
        text-align: center;
        padding-bottom: 6%;
        .check-container {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 3rem;
          margin-bottom: 5rem;
          .check-icon-container {
            height: 5rem;
            width: 5rem;
            background: #6e6e9f33;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            .check {
              height: 2rem;
            }
          }
        }
        .info {
          .header {
            font-family: "Inter";
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.8rem;
            text-align: center;
          }
          .info-content {
            font-family: Urbanist;
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 1.3rem;
            letter-spacing: 0.20000000298023224px;
            span {
              background: -webkit-linear-gradient(#9696be, #2d2d55);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
.page2 {
  width: 100%;
  background-color: $primary-blue;

  .border {
    width: 100%;
    height: 0.3rem;
    background-color: $secondary-brown;
  }
  .BookingPageTwo {
    height: 99%;
    width: 100%;
    display: flex;
    justify-content: center;
    .left-container {
      height: 90%;
      width: 35%;
      display: flex;
      .logo-witmeg {
        height: 5.1rem;
        width: 7.1rem;
        margin: auto;
        margin-top: 2rem;
        margin-left: -6%;
        img {
          height: 5.1rem;
          width: 7.1rem;
        }
      }
    }
    .right-container {
      margin-top: 2%;
      margin-bottom: 2%;
      width: 55%;
      .form-container {
        height: 100%;
        width: 100%;
        background-color: $white;
        border-radius: 0.25rem;
        padding: 0.5rem;

        .form {
          border-top: 1px solid $border-grey;
          margin-top: 0.5rem;
          .error {
            color: red;
            font-family: "Inter";
            font-size: 0.7rem;
          }
          .header {
            margin-top: 1rem;
            margin-left: 1rem;
            color: #000;
            font-family: "Inter";
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.2rem;
          }
          .form-cont {
            padding: 2rem;
            margin-bottom: -2rem;
            .alert-container {
              background-color: #9696be42;
              border: 1px solid $border-grey;
              border-radius: 0.25rem;
              display: flex;
              align-items: center;
              padding: 1rem;

              .image {
                img {
                  height: 1.8rem;
                }
              }
              .content {
                margin-left: 1rem;
                .content-header {
                  color: #414141;
                  font-family: "Inter";
                  font-size: 0.9rem;
                  font-weight: 600;
                }
                .content-sub {
                  margin-top: 0.25rem;
                  color: #505050;
                  font-family: "Inter";
                  font-size: 0.8rem;
                  font-weight: 400;
                }
              }
            }
            .rules-container {
              border-radius: 0.25rem;
              display: flex;
              align-items: center;
              margin-bottom: 3rem;

              .image {
                img {
                  height: 1.8rem;
                }
              }
              .content {
                margin-left: 1rem;
                .content-header {
                  color: #414141;
                  font-family: "Inter";
                  font-size: 0.9rem;
                  font-weight: 600;
                }
                .content-sub {
                  margin-top: 0.25rem;
                  color: #505050;
                  font-family: "Inter";
                  font-size: 0.8rem;
                  font-weight: 400;
                }
              }
            }
            .rest-details {
              display: flex;
              margin-top: 2rem;
              column-gap: 10%;
              margin-bottom: 2rem;

              .det-cont {
                display: flex;

                .image {
                  img {
                    height: 1.6rem;
                  }
                }
                .content {
                  margin-left: 1rem;
                  .content-header {
                    color: #414141;
                    font-family: "Inter";
                    font-size: 0.9rem;
                    font-weight: 600;
                  }
                  .content-sub {
                    margin-top: 0.25rem;
                    color: #505050;
                    font-family: "Inter";
                    font-size: 0.8rem;
                    font-weight: 400;
                  }
                }
              }
            }

            .name-container {
              display: flex;
              column-gap: 3rem;
              margin-bottom: 1rem;
              .input-flex {
                width: 45%;
              }
            }
            .guest-container {
              width: 96%;
              margin-bottom: 1rem;
            }
            .policy-content {
              margin-top: 1rem;
              color: $light-grey;
              font-family: "Inter";
              font-size: 0.7rem;
              font-weight: 400;
              line-height: 1.2rem;
              display: flex;
              align-items: center;
              margin-bottom: 2rem;
              .unchecked {
                height: 1rem;
                width: 1rem;
                border: 1px solid $drak-grey;
                margin-right: 1rem;
                border-radius: 0.2rem;
                cursor: pointer;
              }
              .checked {
                height: 1rem;
                width: 1rem;
                border: 1px solid #2d2d55;
                margin-right: 1rem;
                border-radius: 0.2rem;
                cursor: pointer;
                background-color: #9696be;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  height: 0.75rem;
                  width: 0.75rem;
                }
              }
              span {
                background: -webkit-linear-gradient(#9696be, #2d2d55);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
                margin-left: 0.2rem;
                margin-right: 0.2rem;
              }
            }
          }
        }
      }
      .button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 1.5rem;
        column-gap: 1rem;
        .back-btn {
          padding: 0.75rem 3rem;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1rem;
          cursor: pointer;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .page {
    .BookingPageOne {
      display: block;
      .left-container {
        width: 100%;
      }
      .right-container {
        width: 94%;
        margin-top: 10%;
        padding-bottom: 10%;
        .form-container {
          margin: auto;
        }
      }
    }
    .BookingPageThree {
      display: block;
      .left-container {
        width: 100%;
      }
      .right-container {
        width: 96%;
        margin-top: 10%;
        padding-bottom: 10%;
        .form-container {
          margin: auto;
          padding-bottom: 10%;
        }
      }
    }
  }
  .page2 {
    .BookingPageTwo {
      display: block;
      .left-container {
        width: 100%;
      }
      .right-container {
        width: 96%;
        margin-top: 10%;
        padding-bottom: 10%;
        .form-container {
          .form {
            margin: auto;
            .header {
              margin-top: 3rem;
            }
            .form-cont {
              padding: 1rem;
              .name-container {
                display: block;
                .input-flex {
                  width: 91%;
                  margin-bottom: 1rem;
                }
              }
              .guest-container {
                width: 91%;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-device-width: 600px) and (max-width: 900px) {
  .page {
    .BookingPageOne {
      display: block;
      .left-container {
        width: 100%;
        height: auto;
      }
      .right-container {
        margin-top: 0;
        width: 97%;
        height: auto;
        padding-bottom: 10%;
        .form-container {
          margin: auto;
          min-height: 51.1vh;
        }
      }
    }
    .BookingPageThree {
      display: block;
      .left-container {
        width: 100%;
      }
      .right-container {
        width: 98%;
        padding-bottom: 10%;
        .form-container {
          margin: auto;
          padding-bottom: 10%;
        }
      }
    }
  }
  .page2 {
    .BookingPageTwo {
      display: block;
      .left-container {
        width: 100%;
      }
      .right-container {
        width: 98%;
        padding-bottom: 10%;
        .form-container {
          .form {
            margin: auto;
            .form-cont {
              .name-container {
                display: block;
                .input-flex {
                  width: 95%;
                  margin-bottom: 1rem;
                }
              }
              .guest-container {
                width: 95%;
              }
            }
          }
        }
      }
    }
  }
}
