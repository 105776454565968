@import "../../../colors/colors.scss";

.LoginStyles {
  height: 100vh;
  width: 100%;
  background-color: $white;
  display: flex;
  .gradient-text {
    font-family: "Inter";
    font-size: 0.875rem;
    font-weight: 600;
    background: -webkit-linear-gradient(50.47deg, #f68bff 0%, #6f47eb 83.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }
  .left-login-container {
    width: 29%;
    height: 100vh;
    background-image: url("../../../assets/Image.png");
    background-repeat: repeat-x;
    background-size: cover;
    background-position: center;
    .logo-container {
      height: 60%;
      padding-left: 10%;
      padding-top: 10%;
      img {
        height: 2rem;
      }
    }
    .text-container {
      height: 20%;
      font-family: "Inter";
      font-size: 32px;
      font-weight: 500;
      line-height: 46px;
      text-align: left;
      color: $white;
      padding-left: 10%;
      width: 80%;
    }
  }
  .right-login-container {
    width: 71%;
    height: auto;
    display: flex;
    .form-container {
      width: 40%;
      height: auto;
      margin: auto;
      .header {
        font-family: "Inter";
        font-size: 1.5rem;
        font-weight: 600;
      }
      .form {
        margin-top: 10%;
        width: 100%;
        .error {
          color: red;
          font-family: "Inter";
          font-size: 0.7rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
          img {
            height: 0.75rem;
            width: 0.75rem;
          }
        }
      }
    }
  }
}
