@import "../../../colors/colors.scss";

.Reservation {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  .MuiPhoneNumber-flagButton {
    height: 16px;
    padding: 0;
    min-width: 16px;
  }
  .css-1eed5fa-MuiInputBase-root-MuiInput-root {
    font-family: "Inter";
    font-weight: 400;
    font-size: 0.75rem;
    color: #202020;
  }
  .reservation-content {
    width: 100%;
    background-color: #f9f9f9;
    position: relative;

    .loader {
      border: 0.1rem solid #9d9d9d;
      border-top: 0.15rem solid transparent;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      animation: spin 1s linear infinite;
      margin: auto;
      margin-top: 10%;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .list-view {
      margin-top: 4.05rem;
      .table-view {
        padding: 3%;
        overflow-y: auto;
        padding-bottom: 8%;
        padding-top: 5%;
      }
    }
    .floor-view {
      overflow: hidden;
      height: 91vh;
      width: 100%;
      display: flex;
      position: relative;
      margin-top: 4.05rem;
      .left-view {
        width: 28%;
        height: 100%;
        background-color: transparent;
        border-right: 1px solid #ededed;
        position: relative;
        overflow: auto;
        background-color: #fff;
        .fixed-view {
          position: fixed;
          width: 26%;
          z-index: 10;
          .header-container {
            display: flex;
            padding-top: 1.375rem;
            width: 100%;
            border-bottom: 1px solid #ededed;
            background-color: #fff;

            .content {
              width: inherit;
              text-align: center;
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 600;
              color: #84818a;
              padding-bottom: 0.75rem;
            }
            .content-selected {
              background: -webkit-linear-gradient(
                50.47deg,
                #f68bff 0%,
                #6f47eb 83.75%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              border-bottom: 2px #6f47eb solid;
            }
          }
          .search-container {
            padding: 1.25rem 2.5rem;
            border-bottom: 1px solid #ededed;
            background-color: #fff;
          }
        }

        .table-view {
          padding: 3%;
          padding-bottom: 20%;
          padding-top: 12rem;
        }
      }
      .right-view {
        width: 73%;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    .reservation-view {
      margin-top: 4.05rem;
      display: flex;
      position: relative;
      overflow: hidden;
      height: 91vh;
      .left-view {
        width: 28%;
        border-right: 1px solid #ededed;
        overflow-y: auto;
        overflow-x: hidden;
        .guest-info {
          background-color: #fff;
          padding: 1.25rem 1.5rem;
          .header {
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 500;
            color: #202020;
            display: flex;
            column-gap: 0.5rem;
            align-items: center;
            margin-bottom: 1.25rem;
            img {
              height: 0.875rem;
              width: 0.87rem;
            }
          }
          .name-container {
            display: flex;
            width: 100%;
            column-gap: 2.5rem;
            margin-top: 0.5rem;
            .error {
              color: red;
              font-family: "Inter";
              font-size: 0.7rem;
            }
            .left {
              width: 40%;
            }
            .right {
              width: 45%;
            }
          }
        }
        .inner-container {
          background-color: #fff;
          height: auto;
          .item-cont {
            border-bottom: #e8e8e8 1px solid;
            .alert-danger {
              width: 78%;
              margin: auto;
              border: 1px solid #ebadad;
              background-color: #faebeb;
              padding: 0.75rem;
              border-radius: 0.25rem;
              display: flex;
              align-items: center;
              margin-top: 1rem;

              .left-content {
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 400;
                color: #1a1a1a;
                width: 90%;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                .cancel {
                  height: 1rem;
                  width: 1rem;
                  background-color: #cc3333;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  column-gap: 0.5rem;
                  justify-content: center;
                  img {
                    height: 0.5rem;
                    width: 0.5rem;
                  }
                }
              }
              .right-content {
                width: 10%;
                display: flex;
                justify-content: flex-end;
                img {
                  height: 0.75rem;
                  width: 0.75rem;
                  filter: brightness(0) saturate(100%) invert(52%) sepia(2%)
                    saturate(3%) hue-rotate(326deg) brightness(97%)
                    contrast(80%);
                }
              }
            }
            .alert-warning {
              width: 78%;
              margin: auto;
              border: 1px solid #f7e1a1;
              background-color: #fdf8e8;
              padding: 0.75rem;
              border-radius: 0.25rem;
              display: flex;
              align-items: center;
              margin-top: 1rem;

              .left-content {
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 400;
                color: #1a1a1a;
                width: 90%;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                .cancel {
                  height: 1rem;
                  width: 1rem;
                  background-color: #deaf21;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  column-gap: 0.5rem;
                  justify-content: center;
                  .danger {
                    height: 0.5rem;
                    width: 0.5rem;
                  }
                  .warning {
                    height: 0.85rem;
                    width: 0.85rem;
                  }
                }
              }
              .right-content {
                width: 10%;
                display: flex;
                justify-content: flex-end;
                img {
                  height: 0.75rem;
                  width: 0.75rem;
                  filter: brightness(0) saturate(100%) invert(52%) sepia(2%)
                    saturate(3%) hue-rotate(326deg) brightness(97%)
                    contrast(80%);
                }
              }
            }
            .item {
              padding: 1.188rem;
              font-family: "Inter";
              font-size: 0.8rem;
              font-weight: 500;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              position: relative;
              .det {
                display: flex;
                align-items: center;
                column-gap: 2.5rem;
                width: 100%;
                .head {
                  display: flex;
                  align-items: center;
                  column-gap: 0.75rem;
                  font-family: "Inter";
                  font-size: 14px;
                  font-weight: 500;
                  color: #202020;
                  width: 150px;
                  img {
                    height: 0.75rem;
                    width: 0.75rem;
                  }
                }
                .gradient-text {
                  background: linear-gradient(
                    50.47deg,
                    #f68bff 0%,
                    #6f47eb 83.75%
                  );

                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  cursor: pointer;
                }
                .sub {
                  font-family: "Inter";
                  font-size: 0.8rem;
                  font-weight: 500;
                  text-align: left;
                  font-family: "Inter";
                  font-size: 14px;
                }
              }
              .arrow-cont {
                img {
                  height: 0.75rem;
                  width: 0.75rem;
                  transform: rotate(90deg);
                }
              }
              .arrow-cont-sel {
                display: flexzs;
                align-items: center;
                img {
                  height: 0.875rem;
                  width: 0.875rem;
                  transform: rotate(-90deg);
                }
              }
              .status-list {
                position: absolute;
                width: 14.438rem;
                background-color: #fff;
                right: -14.438rem;
              }
            }
            .item-sel {
              background: #f5f1ff;
            }
            .input-cont {
              width: 85%;
              margin-left: 3%;
              margin-bottom: 2rem;
            }
            .date-cont {
              height: 18rem;
              width: 85%;
              left: 36%;
              margin-top: -6%;
              position: absolute;
              .iner {
                transform: none !important;
              }
            }
          }
        }
        .status-container {
          padding: 1.25rem 1.5rem;
          border-top: #e8e8e8 solid 1px;
          border-bottom: #e8e8e8 solid 1px;
          display: flex;
          justify-content: space-between;
          background-color: #f9f9f9;
          .item {
            background-color: #fff;
            width: 42%;
            border: 1.2px solid #00000024;
            padding: 10px;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            font-family: "Inter";
            font-size: 0.75rem;
            font-weight: 500;
            .check {
              height: 1rem;
              width: 1rem;
              border: 1px solid #cccccc;
              border-radius: 3px;
              margin-right: 2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              .checked {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 1rem;
                width: 1rem;
                background: linear-gradient(
                  50.47deg,
                  #f68bff 0%,
                  #6f47eb 83.75%
                );

                img {
                  height: 0.7rem;
                  width: 0.7rem;
                  filter: brightness(0) saturate(100%) invert(100%) sepia(8%)
                    saturate(29%) hue-rotate(228deg) brightness(107%)
                    contrast(99%);
                  margin: 0;
                }
              }
              img {
                height: 1rem;
                width: 1rem;
              }
            }
            img {
              height: 0.9rem;
              width: 0.9rem;
              margin-right: 0.5rem;
            }
          }
        }
        .btn-container {
          padding: 1.25rem 1.5rem;
          border-top: #e8e8e8 solid 1px;
        }
      }
      .right-view {
        width: 73%;
        overflow: auto;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #dfdfdf;
  }
}
