.SideBar {
  width: 4.95rem;
  height: 100vh;
  background: #f9f9f9;
  border-right: 1px solid #ededed;
  position: fixed;
  .homeIC {
    height: 1.75rem;
    width: max-content;
    margin: auto;
    padding-top: 1.125rem;
    margin-bottom: 2.125rem;
    cursor: pointer;
  }
  .addBtn {
    background-color: #6f47eb;
    height: 2.5rem;
    width: 3.875rem;
    margin: auto;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.2rem;
    cursor: pointer;

    img {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
  .iconList {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.2rem;
    img {
      cursor: pointer;
    }
  }
  .expand {
    position: absolute;
    bottom: 3rem;
    right: 1.5rem;
    height: 1.6rem;
    width: 1.6rem;
    background-color: #6f47eb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;

    img {
      height: 0.75rem;
      width: 0.75rem;
      transform: rotate(90deg);
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
        hue-rotate(285deg) brightness(105%) contrast(102%);
    }
  }
}
