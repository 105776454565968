.booking-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.booking-modal-content {
  background: white;
  width: 32rem;
  border: 1px solid #ebebeb;
  height: 80vh;
  margin: auto;
  overflow-y: auto;
  .table-name {
    padding: 1rem 1.375rem;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    color: #202020;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;

      .image {
        height: 1rem;
        width: 1rem;
        display: flex;
        align-items: center;
        img {
          height: 0.813rem;
          width: 0.813rem;
        }
      }
    }
    .right {
      img {
        height: 1rem;
        width: 1rem;
        filter: brightness(0) saturate(100%) invert(4%) sepia(2%)
          saturate(4659%) hue-rotate(201deg) brightness(99%) contrast(87%);
        cursor: pointer;
      }
    }
  }
  .covers-name {
    padding: 1rem 1.375rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    .head {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 600;
      color: #202020;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .image {
        height: 1rem;
        width: 1rem;
        display: flex;
        align-items: center;
        img {
          height: 1rem;
          width: 1rem;
        }
      }
    }
    .cvr-cont {
      display: flex;
      align-items: center;
      padding-left: 1.5rem;
      column-gap: 1rem;
      .cont {
        display: flex;
        align-items: center;
        height: 2.5rem;
        background: #f9f9f9;
        border: 1px solid #e8e8e8;
        width: 6.25rem;
        border-radius: 0.25rem;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        color: #202020;

        .cont-1 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 100%;
        }
        .cont-2 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 100%;
          background-color: #fff;
          border-left: 1px solid #e8e8e8;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
  }
  .time-name {
    padding: 1rem 1.375rem;
    .head {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 600;
      color: #202020;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .image {
        height: 1rem;
        width: 1rem;
        display: flex;
        align-items: center;
        img {
          height: 1rem;
          width: 1rem;
        }
      }
      .no-res {
        background: #00a5ff1a;
        font-family: "Manrope";
        font-size: 0.75rem;
        font-weight: 600;
        color: #00a5ff;
        padding: 0.219rem 0.375rem;
        border-radius: 0.25rem;
      }
    }
    .btn-container {
      margin-top: 2.2rem;
    }
    .content {
      min-height: max-content;
      border: 1px solid #e4e4e4;
      border-radius: 0.5rem;
      margin-top: 1.2rem;
      .no-booking {
        font-family: "Inter";
        font-size: 0.875rem;
        font-weight: 400;
        color: #84818a;
        padding: 1rem 1.5rem;
      }

      .item-cover {
        border-bottom: 1px solid #ebebeb;
        font-family: "Inter";
        font-size: 1rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0.75rem 1.5rem;
        justify-content: space-between;
        .right {
          display: flex;
          align-items: center;
          img {
            height: 0.875rem;
            width: 0.875rem;
            transform: rotate(90deg);
            filter: brightness(0) saturate(100%) invert(51%) sepia(1%)
              saturate(2844%) hue-rotate(241deg) brightness(102%) contrast(73%);
          }
          .status {
            font-family: "Inter";
            font-size: 10px;
            font-weight: 400;
            padding: 0.4rem 0.6rem;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            margin-right: 1.5rem;
            img {
              transform: none;
              height: 1rem;
              width: 1rem;
            }
          }
        }
        .left {
          display: flex;
          align-items: center;
          .booking-details {
            display: flex;
            align-items: center;
            column-gap: 1.5rem;
            margin-left: 2.1rem;
            .det {
              display: flex;
              align-items: center;
              column-gap: 0.5rem;
              .table {
                font-family: "Inter";
                font-size: 12px;
                font-weight: 500;
                display: flex;
                align-items: center;
                column-gap: 0.2rem;
                .tbl {
                  height: 1.438rem;
                  width: 1.438rem;
                  background: #20c9ac1a;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    height: 0.5rem;
                    transform: none;
                    filter: none;
                  }
                }
              }
              .cover {
                font-family: "Inter";
                font-size: 12px;
                font-weight: 500;
                display: flex;
                align-items: center;
                column-gap: 0.2rem;
                .cvr {
                  height: 1.438rem;
                  width: 1.438rem;
                  background: #00a5ff1a;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    height: 1rem;
                    transform: none;
                    filter: none;
                  }
                }
              }
            }
            .id-det {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: "Inter";
              font-size: 0.625rem;
              font-weight: 500;
              color: #5c5f62;
              width: 5rem;
              height: 1.25rem;
              background-color: #ededed;
              border-radius: 0.15rem;
            }
          }
        }
      }
      .item-cover:last-child {
        border-bottom: none;
      }
      .selected {
        background: #9494c51a;
        .gradient-text {
          background: linear-gradient(50.47deg, #7e7ed9 0%, #2d2d55 83.75%);

          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          cursor: pointer;
        }
      }
    }
  }
}
